import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";

const AuthLayout = (props: any) => {
  const { t } = useTranslation(); // Multi Language
  document.body.classList.add("auth-body-background-color"); // On ajoute la couleur de fond de la platforme
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const changeLanguage = async (lng: any) => {
    await i18n.changeLanguage(lng);
    setCurrentLanguage(i18n.language);
    i18n.reloadResources();
  };
  return (
    <div className="auth-body">
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="mt-10">{props.children}</div>
          <div className="d-flex align-items-center justify-content-center mt-4">
            <button
              onClick={() => changeLanguage("en")}
              className={`btn btn-sm mx-4 ${
                currentLanguage === "en" ? "btn-success" : "btn-light"
              }`}
            >
              English
              <img
                className="w-15px h-15px rounded-1 ms-2"
                src="/images/flags/united-states.svg"
                alt=""
              />
            </button>{" "}
            <button
              onClick={() => changeLanguage("fr")}
              className={`btn btn-sm ${
                currentLanguage === "fr" ? "btn-success" : "btn-light"
              }`}
            >
              Français
              <img
                className="w-15px h-15px rounded-1 ms-2"
                src="/images/flags/france.svg"
                alt=""
              />
            </button>
          </div>
          <div className="text-center mt-3">
            v.{process.env.REACT_APP_VERSION}
          </div>
          <div className="text-center mt-3 text-dark">
            <NavLink to="/contact" className="text-dark">
              {t("core:pages.account.layout.menu.contact")}
            </NavLink>{" "}
            |{" "}
            <a href="/legals/cgu.html" target="blank" className="text-dark">
              {t("core:layout.footer.cgu")}
            </a>{" "}
            |{" "}
            <a href="/legals/cgv.html" target="blank" className="text-dark">
              {t("core:layout.footer.cgv")}
            </a>{" "}
            |{" "}
            <a href="/legals/privacy.html" target="blank" className="text-dark">
              {t("core:layout.footer.privacy")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any,
};

export default AuthLayout;
